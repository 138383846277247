import { type Ref, onErrorCaptured, provide, ref, unref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

interface Error {
  message: string
  step: string | Ref<string>
}

export type ErrorData = Ref<Error>

export const useCatcher = () => {
  const logger = useLogger()

  const errorData = ref<Error>({
    message: '',
    step: '',
  })

  provide('errorData', errorData)

  onErrorCaptured((error, instance, info) => {
    if (errorData.value.message !== '') {
      // eslint-disable-next-line no-underscore-dangle
      const componentName = instance?.$options?.__name || 'unknown'

      logger.error(errorData.value.message, {
        componentName,
        step: unref(errorData.value.step),
        error,
        info,
        owners: ['bot-squad-circularity-customer-front'],
      })
    }

    return false
  })
}
