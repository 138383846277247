export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preconnect","href":"https://front-office.statics.backmarket.com"},{"rel":"preconnect","href":"https://static-ds.backmarket.com"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"},{"rel":"preload","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css","as":"style"},{"rel":"stylesheet","href":"https://static-ds.backmarket.com/fonts/v8.6.0/backmarket.fontface.css"}],"style":[],"script":[],"noscript":[],"viewport":"width=device-width, initial-scale=1, maximum-scale=1","bodyAttrs":{"class":"bg-surface-default-mid"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false