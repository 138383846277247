export const CLAIM_KIND_ENUM = {
  bmWarranty: 'BACKMARKET_WARRANTY',
  merchant: 'MERCHANT',
} as const

export const DECLARE_WARRANTY_STATE_ENUM = {
  inWarranty: 'IN_WARRANTY',
  thirdPartyRepair: 'THIRD_PARTY_REPAIR',
  waterOrDropped: 'WATER_OR_DROPPED',
} as const

export const ORDERLINE_STATE_ENUM = {
  new: 'NEW',
  paid: 'PAID',
  validated: 'VALIDATED',
  shipped: 'SHIPPED',
  cancelled: 'CANCELLED',
  refundedPreDelivery: 'REFUNDED_PRE_DELIVERY',
  refundedPostDelivery: 'REFUNDED_POST_DELIVERY',
  notPaid: 'NOT_PAID',
  pending: 'PENDING',
  onHold: 'ON_HOLD',
  replaced: 'REPLACED',
} as const

export const RESOLUTION_ENGINE_SERVICE_ENUM = {
  afterSalesPlatform: 'ASP',
  backRepair: 'BACKREPAIR',
} as const

export const RESOLUTION_STATE_ENUM = {
  closed: 'CLOSED',
  created: 'CREATED',
  failed: 'FAILED',
  initiated: 'INITIATED',
  initiationFailed: 'INITIATION_FAILED',
  ongoing: 'ONGOING',
} as const

export const RESOLUTION_OPTIONS_ACTIONS_ENUM = {
  createShipment: 'CREATE_SHIPMENT',
  showDataRemoval: 'SHOW_DATA_REMOVAL',
  showPictures: 'SHOW_PICTURES',
  showPicturesWithIntro: 'SHOW_PICTURES_WITH_INTRO',
  showMessage: 'SHOW_MESSAGE',
} as const

export const RESOLUTION_OPTION_FALLBACK_REASON_ENUM = {
  shipment: 'SHIPMENT',
  failure: 'FAILURE',
  resolutionFailed: 'RESOLUTION_FAILED',
  asyncPayment: 'ASYNC_PAYMENT',
}

export const RESOLUTION_OPTION_SERVICE_NAME_ENUM = {
  asp: 'ASP',
  backRepair: 'BACKREPAIR',
  orderManagement: 'ORDER_MANAGEMENT',
} as const

export const RESOLUTION_OPTION_TYPE_ENUM = {
  chat: 'CHAT',
  factoryRepair: 'FACTORY_REPAIR',
  shopRepair: 'SHOP_REPAIR',
  repairReplace: 'REPAIR_REPLACE',
  remoteAssistance: 'REMOTE_ASSISTANCE',
  refund: 'REFUND',
  sellerRepair: 'SELLER_REPAIR',
  sellerReplace: 'SELLER_REPLACE',
  replace: 'REPLACE',
  liveChat: 'LIVE_CHAT',
  straightRefund: 'STRAIGHT_REFUND',
} as const

export type GetClaimKindEnum =
  (typeof CLAIM_KIND_ENUM)[keyof typeof CLAIM_KIND_ENUM]

export type GetDeclaredWarrantyStateEnum =
  (typeof DECLARE_WARRANTY_STATE_ENUM)[keyof typeof DECLARE_WARRANTY_STATE_ENUM]

export type GetOrderlineStateEnum =
  (typeof ORDERLINE_STATE_ENUM)[keyof typeof ORDERLINE_STATE_ENUM]

export type GetResolutionEngineServiceEnum =
  (typeof RESOLUTION_ENGINE_SERVICE_ENUM)[keyof typeof RESOLUTION_ENGINE_SERVICE_ENUM]

export type GetResolutionStateEnum =
  (typeof RESOLUTION_STATE_ENUM)[keyof typeof RESOLUTION_STATE_ENUM]

export type GetResolutionOptionFallbackReasonEnum =
  (typeof RESOLUTION_OPTION_FALLBACK_REASON_ENUM)[keyof typeof RESOLUTION_OPTION_FALLBACK_REASON_ENUM]

export type GetResolutionOptionsActionsEnum =
  (typeof RESOLUTION_OPTIONS_ACTIONS_ENUM)[keyof typeof RESOLUTION_OPTIONS_ACTIONS_ENUM]

export type GetResolutionOptionsServiceNameEnum =
  (typeof RESOLUTION_OPTION_SERVICE_NAME_ENUM)[keyof typeof RESOLUTION_OPTION_SERVICE_NAME_ENUM]

export type GetResolutionOptionsTypeEnum =
  (typeof RESOLUTION_OPTION_TYPE_ENUM)[keyof typeof RESOLUTION_OPTION_TYPE_ENUM]
