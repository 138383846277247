/* eslint-disable @typescript-eslint/no-unused-vars */
import type { H3Event } from 'h3'

import { trace } from '@backmarket/nuxt-module-instrumentation/trace'
import { merge } from '@backmarket/utils/object/merge'
import { omit } from '@backmarket/utils/object/omit'
import { resolvePath } from '@backmarket/utils/url/resolvePath'

import { type ErrorPayloadNormalized, fromAnyError } from './normalizeError'

type BaseRequestOptions = {
  body?: Record<string, unknown> | undefined
  query?: Record<string, unknown> | undefined
}

type FilterOutUndefinedOptions<Opts> = {
  [K in keyof Opts as undefined extends Opts[K] ? never : K]: Opts[K]
}

/**
 * SmartOmit omits the keys like Omit, but does not break when the generic is a union between undefined an a Record
 */
type SmartOmit<T, K extends string | number> = T extends undefined
  ? T
  : Omit<T, K>

export type HttpFetchOptionsWithoutMethod<
  Path extends string = string,
  RequestOptions extends BaseRequestOptions = BaseRequestOptions,
> = SmartOmit<
  Parameters<typeof $fetch>[1],
  'body' | 'query' | 'pathParams' | 'method'
> &
  RequestOptions &
  PathParams<Path>
export type HttpFetchOptions<
  Method extends MethodType = 'GET',
  Path extends string = string,
  RequestOptions extends BaseRequestOptions = BaseRequestOptions,
> = SmartOmit<Parameters<typeof $fetch>[1], 'body' | 'query' | 'pathParams'> & {
  method?: Method
} & RequestOptions &
  PathParams<Path>

type ExtractDynamicParams<Path extends string> =
  Path extends `${infer A extends string}/:${infer Param extends string}/${infer B extends string}`
    ? Param | ExtractDynamicParams<A> | ExtractDynamicParams<B>
    : Path extends `${infer A extends string}/:${infer Param extends string}`
      ? Param | ExtractDynamicParams<A>
      : never

type PathParams<Path extends string> =
  ExtractDynamicParams<Path> extends never
    ? {
        pathParams?: Record<string, string | number>
      }
    : { pathParams: Record<ExtractDynamicParams<Path>, string | number> }

type URL = string

type EndpointDefinition<
  Response = unknown,
  RequestOptions extends BaseRequestOptions = BaseRequestOptions,
> = { response: Response } & RequestOptions

type MethodType = 'POST' | 'PATCH' | 'DELETE' | 'GET' | 'PUT' | 'OPTIONS'

type EndpointObject = Partial<Record<MethodType, EndpointDefinition>>

type EndpointResponse<
  Specs extends BaseApiSpecs,
  Path extends keyof Specs,
  Method extends MethodType,
> =
  Specs[Path][Method] extends EndpointDefinition<infer Response>
    ? Response
    : never

export type BaseApiSpecs = Record<URL, EndpointObject>
export interface HttpFetch<ApiSpecs extends BaseApiSpecs> {
  <Path extends keyof ApiSpecs & string, Method extends MethodType = 'GET'>(
    request: Path,
    requestOptions: HttpFetchOptions<
      Method,
      Path,
      ApiSpecs[Path][Method] extends EndpointDefinition<
        infer R,
        infer RequestOptions
      >
        ? FilterOutUndefinedOptions<Omit<RequestOptions, 'response'>>
        : never
    >,
    // It was initially `ReturnType<typeof $fetch>`. However, it looks like
    // such "complex" types sometimes end up causing excessive stack depth
    // on TypeScript side. By "inlining" the type, we get rid of the error.
    // See https://github.com/unjs/nitro/issues/470.
  ): Promise<EndpointResponse<ApiSpecs, Path, Method>>

  // this overloads the "function" part of the HttpFetch interface
  // Whe no method is passed in requestOptions, then it's equivalent to method: "GET"
  <Path extends keyof ApiSpecs & string>(
    request: Path,
    requestOptions: HttpFetchOptionsWithoutMethod<
      Path,
      ApiSpecs[Path]['GET'] extends EndpointDefinition<
        infer R,
        infer RequestOptions
      >
        ? FilterOutUndefinedOptions<Omit<RequestOptions, 'response'>>
        : never
    >,
  ): Promise<EndpointResponse<ApiSpecs, Path, 'GET'>>

  // this overloads the "function" part of the HttpFetch interface
  // When only 1 argument is passed, then it's equivalent to requestOptions: { method: "GET "}
  <Path extends keyof ApiSpecs & string>(
    request: Path,
  ): Promise<EndpointResponse<ApiSpecs, Path, 'GET'>>

  create: <S extends undefined | BaseApiSpecs = undefined>(
    options: Partial<CreateHttpFetchOptions>,
  ) => HttpFetch<undefined extends S ? ApiSpecs : ApiSpecs & S>
}

// TODO: remove interface once migration to type-safe useHttpFetch as been completed
export interface TypeUnsafeHttpFetch {
  <ResponseBody = unknown>(
    request: string,
    requestOptions?: HttpFetchOptions<MethodType, string>,
    // It was initially `ReturnType<typeof $fetch>`. However, it looks like
    // such "complex" types sometimes end up causing excessive stack depth
    // on TypeScript side. By "inlining" the type, we get rid of the error.
    // See https://github.com/unjs/nitro/issues/470.
  ): Promise<ResponseBody>

  create: (options: Partial<CreateHttpFetchOptions>) => TypeUnsafeHttpFetch
}

type CreateHttpFetchOptions = {
  /**
   * A reference to the current event being handled by the server.
   */
  event?: H3Event

  /**
   * Returns the default base URL to use for every request. It may be overridden
   * when actually firing the HTTP request via the `baseURL` request option.
   *
   * @example
   * createHttpFetch({
   *   getDefaultBaseUrl() {
   *     return 'https://base-url.com'
   *   },
   * })
   */
  getDefaultBaseUrl?: () => string

  /**
   * Returns the default headers to use for every request. Again, those headers
   * may be tweaked when actually firing the HTTP request via the `headers`
   * option. Note that both headers dictionary will be merged together.
   *
   * @example
   * createHttpFetch({
   *   getDefaultHeaders() {
   *     return { source: 'test' }
   *   },
   * })
   */
  getDefaultHeaders?: () => Record<string, string>
}

const EVENT_LISTENERS = [
  'onRequest',
  'onRequestError',
  'onResponse',
  'onResponseError',
] as const

/**
 * The following is linked to the bot challenge management handled by BOPIP.
 * If you want more details about this, please refer to their documentation.
 */
const BOT_CHALLENGE = {
  TYPE: 'bot-need-challenge',
  MESSAGE: 'BOT_NEED_CHALLENGE error received during server side rendering',
  REDIRECT: '/testchallengepage',
}

/**
 * Create a new `$httpFetch` instance tailored to specific needs. The returned
 * function is configured via the options passed as single parameter. Note that
 * all default options may be overridden when actually executing `$httpFetch`.
 *
 * We're using getters to retrieve the default options instead of raw values
 * because we want options to be read when the HTTP request is actually fired,
 * not when the `$httpFetch` instance is created. Values may change during
 * that time, and we want to rely on the most fresh values as possible.
 *
 * @example
 * const $httpFetch = createHttpFetch({
 *   getDefaultBaseUrl() {
 *     return 'https://base-url.com'
 *   },
 *   getDefaultHeaders() {
 *     return { source: 'test' }
 *   },
 * })
 *
 * const data = await $httpFetch('/endpoint', options)
 *
 * @warning
 * You most likely won't need to call this function yourself though. You are
 * probably interested in using the instance available on the global scope.
 */
export function createHttpFetch<Specs extends BaseApiSpecs>(
  globalOptions?: CreateHttpFetchOptions,
): HttpFetch<Specs> {
  /**
   * Handle bot challenge errors returned from BOPIP.
   */
  function handleBotChallenge(error: ErrorPayloadNormalized): void {
    // We only want to handle bot challenges errors, we ignore other errors.
    if (error.type !== BOT_CHALLENGE.TYPE) return

    // Such errors should not happen on the server side. If they do, it means
    // that BOPIP is likely not behaving as expected.
    if (globalOptions?.event) {
      throw new Error('Bot challenge unexpectedly received server-side')
    }

    // If the error is a bot challenge, we redirect the user to the challenge
    // page where they can solve the challenge and prove they are not a bot.
    window.location.assign(BOT_CHALLENGE.REDIRECT)
  }

  /**
   * Fire an HTTP request, as `$fetch` would do. Most of the function signature
   * is identical to what Nuxt provides out-of-the-box, so you may refer to
   * their documentation to learn more.
   *
   * @see https://nuxt.com/docs/api/utils/dollarfetch
   *
   * One main difference is that our custom `$httpFetch` function is able to
   * compute dynamic paths (i.e. paths including dynamic parameters). It works
   * pretty similarly to how you would expect it to work with Vue Router for
   * example. The following example would fire a request to `/users/123`.
   *
   * @example
   * $httpFetch('/users/:userId', {
   *   pathParams: {
   *     userId: 123,
   *   },
   * })
   */
  function $httpFetch<
    Path extends keyof Specs & string,
    Method extends MethodType,
  >(
    request: Path,
    requestOptions?: HttpFetchOptions<
      Method,
      Path,
      Specs[Path][Method] extends EndpointDefinition<
        infer R,
        infer RequestOptions
      >
        ? FilterOutUndefinedOptions<Omit<RequestOptions, 'response'>>
        : never
    >,
  ): Promise<EndpointResponse<Specs, Path, Method>> {
    /**
     * Simple wrapper around the `$fetch` function to make it easier to call
     * and to avoid repeating the same types over and over again.
     *
     * 1. We cast the return value to `Promise<ResponseBody>` because the
     * typechecking step of _other_ Nuxt modules would fail otherwise. I don't
     * know why, but it seems that the types are not correctly propagated.
     *
     * 2. We also pass `ResponseBody` as a type parameter to `$fetch` to avoid
     * another Nuxt-related issue. When the type is not explicitly provided,
     * the typechecking step crashes because of "Excessive stack depth".
     * See: https://github.com/unjs/nitro/issues/470
     */
    function callFetch(path: string, options: HttpFetchOptions) {
      return $fetch<EndpointResponse<Specs, Path, Method>>(
        path,
        options,
      ) as Promise<EndpointResponse<Specs, Path, Method>>
    }

    const defaultOptions: HttpFetchOptions = {
      baseURL: globalOptions?.getDefaultBaseUrl?.(),
      headers: globalOptions?.getDefaultHeaders?.(),

      // Make sure that cookies are sent with the request. This is obviously
      // important because this is how we authenticate users on the API.
      credentials: 'include',

      method: 'GET',
      // Apply the custom event listeners to the request.
      onRequest: requestOptions?.onRequest,
      onRequestError: requestOptions?.onRequestError,
      onResponseError: requestOptions?.onResponseError,

      onResponse(context) {
        const { response } = context

        if (!response.ok) {
          // We have no other choice than using `_data` to access the content
          // of the response. That's the way `ofetch` works, and we can't do
          // anything about it.
          //
          // Note that by creating an intermediate error variable, we can
          // benefit from TypeScript type inference to get the correct type.
          // If we were to store the return value of `fromAnyError` directly
          // on `response._data`, the type would still be `any`.
          //
          // eslint-disable-next-line no-underscore-dangle
          const error = fromAnyError(response._data)

          // Also, it's expected to reassign the response `_data` property. It
          // is to mutate the response body with its normalized version in order
          // to access it everywhere else (in other `ofetch` listeners or after
          // the error is thrown).
          // eslint-disable-next-line no-param-reassign, no-underscore-dangle
          response._data = error

          handleBotChallenge(error)
        }

        return requestOptions?.onResponse?.(context)
      },
    }

    // Merge everything into an empty object because the `merge` function
    // mutates the destination, and we do not want that. By using an empty
    // object as first parameter, we basically make it immutable.
    const requestOptionsButListeners = omit(requestOptions, EVENT_LISTENERS)
    const options = merge({}, defaultOptions, requestOptionsButListeners)

    const resolvedPath = resolvePath(request, options.pathParams)

    // If there is no event, it means that were executing the code in the
    // browser. Since the `trace` function must only be called on the
    // server, we just fire the HTTP request with no tracing.
    if (globalOptions?.event === undefined) {
      return callFetch(resolvedPath, options)
    }

    const traceOptions = {
      tags: {
        'resource.name': `${options.method} ${request}`,
        'http.method': `${options.method}`,
        'http.url': `${request}`,
      },
    }

    return trace(globalOptions.event, 'fetch', traceOptions, (span, tracer) => {
      if (tracer && span) {
        // Mutate the default headers to inject additional trace-related
        // headers like `x-datadog-trace-id` or `x-datadog-parent-id`.
        tracer.inject(span, 'http_headers', defaultOptions.headers)
      }

      const listeners: Parameters<typeof $fetch>[1] = {
        async onRequestError(context) {
          span?.setTag('error', true)
          span?.setTag('sampling.priority', 1)

          await options.onRequestError?.(context)
        },
        async onResponse(context) {
          span?.setTag('http.status_code', context.response.status)

          await options.onResponse?.(context)
        },
        async onResponseError(context) {
          span?.setTag('error', true)
          span?.setTag('sampling.priority', 1)

          await options.onResponseError?.(context)
        },
      }

      return callFetch(resolvedPath, merge({}, options, listeners))
    })
  }

  /**
   * Create a new `$httpFetch` instance from another `$httpFetch` instance,
   * inheriting the global options used to build the first one.
   *
   * Note that the object returned from the original `getDefaultHeaders` option
   * gets merged with the override and is not simply overridden as the other
   * options. This allows consumers to augment an `$httpFetch` instance with
   * missing headers for example.
   */
  $httpFetch.create = (globalOverrides: Partial<CreateHttpFetchOptions>) => {
    return createHttpFetch({
      ...globalOptions,
      ...globalOverrides,

      // This option is not automatically overridden because we want both the
      // parent and the override functions to be called, and then merge their
      // results together.
      getDefaultHeaders: () => {
        const parentHeaders = globalOptions?.getDefaultHeaders?.()
        const headers = globalOverrides.getDefaultHeaders?.()

        return {
          ...parentHeaders,
          ...headers,
        }
      },
    })
  }

  return $httpFetch
}
