import { useRuntimeConfig } from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

/**
 * Base URL of the BackMarket API.
 *
 * This URL vary, depending on:
 * - whether we are in development mode or not
 * - whether we are on the client side (browser), or on the server side (Node server)
 * - the values of environment variables (see `README.)
 */
export function getHttpBaseUrl() {
  const config = useRuntimeConfig().public.http
  const { market } = useMarketplace()

  return process.server
    ? config.baseUrlServer.replaceAll('{market.domain}', market.domain)
    : config.baseUrlClient.replaceAll('{market.domain}', market.domain)
}
