import type { RouteRecordRaw } from 'vue-router'

import { ROUTES } from '~/scopes/auth/route-names'

import { CHECKOUT } from './routes-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: 'f2',
      name: CHECKOUT.SELF,
      redirect: { name: CHECKOUT.CART },
      component: () => import('./layouts/CheckoutLayout.vue'),
      meta: {
        owners: ['bot-squad-checkout-front'],
        layout: false,
      },
      children: [
        {
          path: 'cart',
          name: CHECKOUT.CART,
          component: () => import('./pages/Cart/Cart.vue'),
          meta: {
            middleware: ['log-attempt'],
            owners: ['bot-squad-checkout-front'],
          },
        },
        {
          path: 'authentication',
          name: CHECKOUT.AUTHENTICATION,
          component: () => import('./pages/Authentication/Authentication.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            guest: {
              required: true,
              redirection: { name: CHECKOUT.ADDRESS_CONFIRMATION },
            },
            middleware: ['log-attempt'],
          },
        },
        {
          path: 'post-authentication',
          name: CHECKOUT.POST_AUTHENTICATION,
          component: () =>
            import('./pages/PostAuthentication/PostAuthentication.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            auth: {
              required: true,
              redirection: { name: CHECKOUT.AUTHENTICATION },
            },
            middleware: ['log-attempt'],
          },
        },
        {
          path: 'shipping/confirmation-address',
          name: CHECKOUT.ADDRESS_CONFIRMATION,
          component: () =>
            import('./pages/AddressConfirmation/AddressConfirmation.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            auth: {
              required: true,
              redirection: { name: ROUTES.AUTH.LOGIN },
            },
            middleware: [
              'log-attempt',
              'redirect-if-cart-empty',
              'redirect-if-missing-shipping',
              'redirect-if-missing-collection-point',
              'redirect-if-address-needs-bouygues-normalization',
            ],
          },
        },
        {
          path: 'shipping/shipping-address',
          name: CHECKOUT.SHIPPING_ADDRESS,
          component: () =>
            import('./pages/ShippingAddress/ShippingAddress.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            auth: {
              required: true,
              redirection: { name: ROUTES.AUTH.LOGIN },
            },
            middleware: ['log-attempt', 'redirect-if-cart-empty'],
          },
        },
        {
          path: 'shipping/billing-address',
          name: CHECKOUT.BILLING_ADDRESS,
          component: () => import('./pages/BillingAddress/BillingAddress.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            auth: {
              required: true,
              redirection: { name: ROUTES.AUTH.LOGIN },
            },
            middleware: ['log-attempt', 'redirect-if-cart-empty'],
          },
        },
        {
          path: 'services/bouygues-telecom',
          redirect: { name: CHECKOUT.SERVICES.BOUYGUES.SIGN_UP },
          meta: {
            owners: ['bot-squad-b2cservices-front'],
            auth: {
              required: true,
              redirection: { name: ROUTES.AUTH.LOGIN },
            },
          },
          children: [
            {
              path: 'sign-up',
              name: CHECKOUT.SERVICES.BOUYGUES.SIGN_UP,
              component: () =>
                import('./pages/BouyguesSignUp/BouyguesSignUp.vue'),
              meta: {
                owners: ['bot-squad-b2cservices-front'],
              },
            },
            {
              path: 'auth',
              name: CHECKOUT.SERVICES.BOUYGUES.AUTH,
              component: () =>
                import(
                  './pages/BouyguesAuthentication/BouyguesAuthentication.vue'
                ),
              meta: {
                owners: ['bot-squad-b2cservices-front'],
              },
            },
            {
              path: 'phoneline-setup',
              name: CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP,
              component: () =>
                import(
                  './pages/BouyguesPhonelineSetup/BouyguesPhonelineSetup.vue'
                ),
              meta: {
                owners: ['bot-squad-b2cservices-front'],
              },
            },
            {
              path: 'not-eligible',
              name: CHECKOUT.SERVICES.BOUYGUES.NOT_ELIGIBLE,
              component: () =>
                import(
                  './pages/BouyguesPhonelineSetup/components/NotEligible/NotEligible.vue'
                ),
              meta: {
                owners: ['bot-squad-b2cservices-front'],
              },
            },
          ],
        },
        {
          path: 'payment',
          name: CHECKOUT.PAYMENT,
          component: () => import('./pages/Payment/Payment.vue'),
          meta: {
            owners: ['bot-squad-checkout-front'],
            auth: {
              required: true,
              redirection: { name: ROUTES.AUTH.LOGIN },
            },
            payment: {
              subdomainEnabled: true,
            },
            middleware: [
              'payment-no-ssr',
              'log-attempt',
              'redirect-if-cart-empty',
              'redirect-if-missing-shipping',
              'redirect-if-missing-collection-point',
              'redirect-bouygues-mobile-plan',
            ],
          },
        },
      ],
    },
    {
      path: 'f2/payment-result/:paymentId?',
      name: CHECKOUT.PAYMENT_RESULT,
      component: () => import('./pages/Result/Result.vue'),
      meta: {
        owners: ['bot-squad-checkout-front'],
        auth: {
          required: true,
          redirection: { name: ROUTES.AUTH.LOGIN },
        },
        middleware: ['log-attempt'],
      },
    },
  ]
}
