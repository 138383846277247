import type { RouteRecordRaw } from 'vue-router'

import { ROUTES as AUTH_ROUTES } from '~/scopes/auth/route-names'
import { ROUTES as ROUTES_NAMES } from '~/scopes/recycling/routes-names'

const routes: RouteRecordRaw[] = [
  {
    path: 'recycling-funnel/category',
    component: () => import('./RecyclingCategory.vue'),
    name: ROUTES_NAMES.CATEGORY,
    meta: {
      owners: ['bot-squad-circularity-customer-front'],
    },
  },
  {
    path: 'recycling-funnel/device/:deviceCategory/offer',
    component: () => import('./RecyclingOffer.vue'),
    name: ROUTES_NAMES.OFFER,
    meta: {
      owners: ['bot-squad-circularity-customer-front'],
      auth: {
        required: true,
        redirection: {
          name: AUTH_ROUTES.AUTH.LOGIN,
          query: { bm_journey: 'recycling' },
        },
      },
    },
  },
]

export default routes
