import { useRoute } from '#imports'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const logger = useLogger()
  const route = useRoute()

  nuxtApp.hook('vue:error', (error, instance) => {
    // eslint-disable-next-line no-underscore-dangle
    const componentName = instance?.$options?.__name || 'unknown'

    if (error instanceof Error) {
      logger.error(error.message, {
        error,
        componentName,
        owners: route.meta.owners || [],
      })
    }
  })

  nuxtApp.hook('app:error', (error) => {
    logger.error(error.message, {
      error,
      owners: route.meta.owners || [],
    })
  })
})
