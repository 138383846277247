import type { RouteRecordRaw } from 'vue-router'

import { ROLES } from '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.constants'

import type { Features } from '~/features'
import { ROUTES as AUTH_ROUTES } from '~/scopes/auth/route-names'
import { CMS } from '~/scopes/cms/routes-names'

import { ROUTE_NAMES } from './constants'

export default ({ features }: { features: Features }): RouteRecordRaw[] => {
  const isBuybackEnabled = features.buyback?.enabled

  if (isBuybackEnabled) {
    return [
      {
        path: 'buyback-funnel',
        component: () => import('./components/TheCatcher/TheCatcherPage.vue'),
        meta: {
          layout: 'minimal',
          owners: ['bot-squad-circularity-customer-front'],
        },
        children: [
          {
            name: ROUTE_NAMES.DEVICE_ASSESSMENT,
            path: 'device/:slug/:id',
            component: () => import('./pages/DeviceAssessment.vue'),
            meta: {
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.OFFER,
            path: 'device/:slug/:id/offer/:listingId',
            component: () => import('./pages/Offer.vue'),
            meta: {
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.NO_OFFER,
            path: 'device/:slug/:id/no-offer',
            component: () => import('./pages/NoOffer.vue'),
            meta: {
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.BANK,
            path: 'device/:slug/:id/offer/:listingId/bank',
            component: () => import('./pages/Bank.vue'),
            meta: {
              auth: {
                required: true,
                redirection: {
                  name: AUTH_ROUTES.AUTH.LOGIN,
                  query: { bm_journey: CMS.BUYBACK },
                },
              },
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.ADDRESS,
            path: 'device/:slug/:id/offer/:listingId/address',
            component: () => import('./pages/Address.vue'),
            meta: {
              auth: {
                required: true,
                redirection: {
                  name: AUTH_ROUTES.AUTH.LOGIN,
                  query: { bm_journey: CMS.BUYBACK },
                },
              },
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.SHIPPING,
            path: 'device/:slug/:id/offer/:listingId/address/:addressId/shipping',
            component: () => import('./pages/Shipping.vue'),
            meta: {
              auth: {
                required: true,
                redirection: {
                  name: AUTH_ROUTES.AUTH.LOGIN,
                  query: { bm_journey: CMS.BUYBACK },
                },
              },
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
          {
            name: ROUTE_NAMES.CONFIRMATION,
            path: 'confirmation/:id',
            component: () => import('./pages/Confirmation.vue'),
            meta: {
              auth: {
                required: true,
                redirection: {
                  name: AUTH_ROUTES.AUTH.LOGIN,
                },
              },
              owners: ['bot-squad-circularity-customer-front'],
            },
          },
        ],
      },
      {
        name: ROUTE_NAMES.CONVERSATION,
        path: 'dashboard/sales/:id/conversation',
        component: () =>
          import(
            '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.vue'
          ),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
            },
          },
          owners: ['bot-squad-circularity-customer-front'],
          layout: 'minimal',
        },
        props: {
          role: ROLES.CUSTOMER,
        },
      },
    ]
  }

  return []
}
