import type { RouteRecordRaw } from 'vue-router'

export const AdminRoutes = {
  HOME: 'admin',
  SELLER: {
    REGISTER: 'bo-seller.register',
    LOGIN: 'bo-seller.login',
  },
}

const routes: readonly RouteRecordRaw[] = [
  {
    name: AdminRoutes.HOME,
    path: '/bo_merchant/admin_tools/dashboard',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      disableClientSideNavigation: () => true,
      owners: ['bot-squad-seller-experience-front'],
    },
  },
  {
    name: AdminRoutes.SELLER.REGISTER,
    path: '/bo-seller/register',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      disableClientSideNavigation: () => true,
      owners: ['bot-squad-seller-experience-front'],
    },
  },
  {
    name: AdminRoutes.SELLER.LOGIN,
    path: '/bo-seller/login',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      disableClientSideNavigation: () => true,
      owners: ['bot-squad-seller-experience-front'],
    },
  },
]

export default routes
