import { navigateTo } from '#imports'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { PAGE_NAME_BY_PLP_UUID } from '../cms/service/content-service.config'

import { ROUTES as PRODUCT_LIST_ROUTES } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const availableUniverseUUID = PAGE_NAME_BY_PLP_UUID[market.countryCode]
  const universeRegex = availableUniverseUUID
    ? Object.keys(availableUniverseUUID).join('|')
    : '!?'

  return [
    {
      path: 'search',
      name: PRODUCT_LIST_ROUTES.SEARCH_RESULTS,
      meta: {
        owners: ['bot-squad-search-and-recommendation-front'],
      },
      component: () => import('./pages/SearchResultPage.vue'),
    },
    {
      path: 's/:merchantName/:uuid',
      name: PRODUCT_LIST_ROUTES.SELLER_PRODUCT_LIST,
      meta: {
        owners: ['bot-squad-search-and-recommendation-front'],
      },
      component: () => import('./pages/SellerProductListPage.vue'),
    },
    {
      path: 'l/:slugV2/:uuid',
      name: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
      meta: {
        owners: ['bot-squad-search-and-recommendation-front'],
        middleware: (to: RouteLocationNormalized) => {
          if (
            Array.isArray(to.params.uuid) || !availableUniverseUUID
              ? false
              : to.params.uuid in availableUniverseUUID
          ) {
            const universePage: RouteLocationNormalized = {
              ...to,
              name: PRODUCT_LIST_ROUTES.UNIVERSE,
            }

            return navigateTo(universePage)
          }

          return undefined
        },
      },
      component: () =>
        import('~/scopes/master-pp/pages/ProductListProxyPage.vue'),
    },
    {
      name: PRODUCT_LIST_ROUTES.UNIVERSE,
      component: () => import('../cms/Landing.vue'),
      path: `l/:slugV2/:uuid(${universeRegex})`,
      meta: {
        owners: ['bot-squad-search-and-recommendation-front'],
      },
    },
  ]
}
