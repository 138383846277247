import { default as indexCV1teRXLqtMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue?macro=true";
import { default as PaymentNoSsrLandingPagenL6FFS47anMeta } from "/var/www/packages/nuxt-layer-payment/scopes/no-ssr/pages/PaymentNoSsrLandingPage/PaymentNoSsrLandingPage.vue?macro=true";
export default [
  {
    name: indexCV1teRXLqtMeta?.name ?? "locale-sr-demopage",
    path: indexCV1teRXLqtMeta?.path ?? "/:locale()/sr-demopage",
    meta: indexCV1teRXLqtMeta || {},
    alias: indexCV1teRXLqtMeta?.alias || [],
    redirect: indexCV1teRXLqtMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue").then(m => m.default || m)
  },
  {
    name: PaymentNoSsrLandingPagenL6FFS47anMeta?.name ?? undefined,
    path: PaymentNoSsrLandingPagenL6FFS47anMeta?.path ?? "/fo-client-only/:destination*",
    meta: { ...(PaymentNoSsrLandingPagenL6FFS47anMeta || {}), ...{"layout":"minimal","owners":["bot-squad-payin-front"],"payment":{"subdomainEnabled":true}} },
    alias: PaymentNoSsrLandingPagenL6FFS47anMeta?.alias || [],
    redirect: PaymentNoSsrLandingPagenL6FFS47anMeta?.redirect,
    component: () => import("/var/www/packages/nuxt-layer-payment/scopes/no-ssr/pages/PaymentNoSsrLandingPage/PaymentNoSsrLandingPage.vue").then(m => m.default || m)
  }
]