import type { RouteRecordRaw } from 'vue-router'

import { ROUTES } from './route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      name: ROUTES.FORM,
      path: 'review/:orderlineId',
      component: () => import('./review-collection/pages/ReviewCollection.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
    {
      name: ROUTES.PAGES.LANDING,
      path: 'r/l/:slugV2/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
    {
      name: ROUTES.PAGES.MERCHANT,
      path: 'r/s/:merchantName/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
    {
      name: ROUTES.PAGES.PRODUCT,
      path: 'r/p/:slugV2/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
    // Matching Pastrami route
    {
      name: ROUTES.PAGES.LEGACY_PRODUCT,
      path: 'r/p/:uuid',
      component: () =>
        import(
          '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
        ),
      meta: {
        disableClientSideNavigation: () => true,
        owners: ['bot-squad-content-front'],
      },
    },
    {
      name: ROUTES.PAGES.ALL,
      path: 'r',
      component: () => import('./reviews-display/pages/Reviews.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
  ]
}
