import type { RouteRecordRaw } from 'vue-router'

export const HELP_CENTER = {
  HOME: 'help_center',
}

export default (): RouteRecordRaw[] => {
  return [
    {
      name: HELP_CENTER.HOME,
      path: 'help',
      component: () => import('./Home.vue'),
      meta: {
        auth: {
          required: false,
        },
        // Required because help-center components are shown/hidden depending on auth state. it prevents UI flashes on full-page reload
        middleware: ['fetch-user'],
        layout: false,
        owners: ['bot-squad-sales-customer-care-front'],
      },
    },
  ]
}
