import type { RouteRecordRaw } from 'vue-router'

import { ROUTES } from './route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      component: () => import('./pages/Login/Login.vue'),
      name: ROUTES.AUTH.LOGIN,
      path: '/auth/login',
      meta: {
        layout: 'minimal',
        owners: ['bot-squad-identity'],
      },
    },
    {
      component: () => import('./pages/ResetPassword/ResetPassword.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD,
      path: '/:locale/password-reset',
      meta: {
        layout: false,
        owners: ['bot-squad-identity'],
      },
    },
    {
      component: () =>
        import('./pages/ResetPasswordConfirm/ResetPasswordConfirm.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD_CONFIRM,
      path: '/:locale/password-reset/confirm',
      meta: {
        layout: false,
        owners: ['bot-squad-identity'],
      },
    },
    {
      component: () => import('./pages/SetNewPassword/SetNewPassword.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD,
      path: '/:locale/password-reset/set-new/:userId/:token',
      meta: {
        owners: ['bot-squad-identity'],
      },
    },
    {
      component: () =>
        import('./pages/SetNewPasswordExpired/SetNewPasswordExpired.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD_EXPIRED,
      path: '/:locale/password-reset/expired-reset',
      meta: {
        owners: ['bot-squad-identity'],
      },
    },
  ]
}
