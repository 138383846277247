/**
 * Nuxt sets the `process.client` property which should be used first, before resorting to use `isBrowser`,
 * because it is removing the useless code from the build.
 *
 * However, use this helper when you need to detect the browser if:
 * - you want to detect it on runtime
 * - `process.client` is not initialized yet
 */
export const isBrowser = () =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Since should be runnable without 'dom' lib
  typeof window !== 'undefined' && typeof window.document !== 'undefined'
