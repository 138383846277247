import type { RouteRecordRaw } from 'vue-router'

import { MODELS_COMPARISON } from './routes-names'

// TODO: move ModelsComparisonHome and ModelsComparisonSearch pages inside a folder of their own

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/:locale/compare',

      children: [
        {
          name: MODELS_COMPARISON.HOME,
          path: 'home',
          component: () => import('./pages/ModelsComparisonHome.vue'),
          meta: {
            owners: ['bot-squad-acquisition-front'],
          },
        },
        {
          name: MODELS_COMPARISON.CATEGORY,
          path: ':category',
          component: () => import('./pages/ModelsComparisonSearch.vue'),
          meta: {
            owners: ['bot-squad-acquisition-front'],
          },
        },
        {
          name: MODELS_COMPARISON.COMPARISON_RESULTS,
          path: ':productA/:productB',
          component: () =>
            import(
              './pages/ModelsComparisonResults/ModelsComparisonResults.vue'
            ),
          meta: {
            owners: ['bot-squad-acquisition-front'],
          },
        },
      ],
    },
  ]
}
