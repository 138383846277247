const IP_V4_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?\/?)$/

/**
 * Domain used by the `splitHostname` function below.
 */
const BACKMARKET_DOMAIN = 'backmarket'

/**
 * Split hostname to get protocol, subdomain, domain, extension, and port.
 *
 * Note that it works only with Back Market domain because it's extremely
 * complex to extract a domain from an arbitrary URL. We have to handle
 * cases with multiple subdomains, multiple extensions, IP addresses,
 * special keywords like localhost, ports, etc. and we can't easily
 * target the domain itself in this giant mess.
 *
 * A nice library to use would be `parse-domain`. It is definitely the most
 * complete one out there, but it also costs quite a lot (35kB as of today).
 *
 * @see https://www.npmjs.com/package/parse-domain
 *
 * Also note that IP v6 are not supported. We rely on `new URL()` under the
 * hood, and IP v6 are not allowed by the `URL` constructor. Thus, they
 * will be treated as any other invalid input.
 *
 * @param {String} input {protocol}//{subdomain}.backmarket.{extension}:{port}
 *
 * @typedef {Object} ReturnType
 * @property {string} protocol
 * @property {string} domain
 * @property {string} subdomain
 * @property {string} extension
 * @property {string} port
 *
 * @return {ReturnType}
 */
export function splitHostname(input: string) {
  try {
    // Consider https the default protocol if none is set for the given input.
    const normalized = input.startsWith('http') ? input : `https://${input}`

    const { hostname, protocol, port } = new URL(normalized)

    if (hostname.includes('localhost')) {
      return {
        domain: 'localhost',
        extension: '',
        subdomain: '',
        protocol,
        port,
      }
    }

    if (hostname.match(IP_V4_REGEX)) {
      return {
        domain: hostname,
        subdomain: '',
        extension: '',
        protocol,
        port,
      }
    }

    const parts = hostname.split('.')
    const domainIndex = parts.findIndex((value) => value === BACKMARKET_DOMAIN)
    const subdomains = parts.slice(0, domainIndex)
    const extensions = parts.slice(domainIndex + 1)

    return {
      domain: BACKMARKET_DOMAIN,
      subdomain: subdomains.join('.'),
      extension: extensions.join('.'),
      protocol,
      port,
    }
  } catch {
    return {
      domain: '',
      subdomain: '',
      extension: '',
      protocol: '',
      port: '',
    }
  }
}
