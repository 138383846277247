import type { RouteRecordRaw } from 'vue-router'

import { DISCOUNT } from './routes-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: 'military-discount',
      name: DISCOUNT.MILITARY,
      component: () => import('~/scopes/cms/Landing.vue'),
      props: {
        simpleLayout: true,
      },
      meta: {
        // Required because the MilitaryDiscount page builds a redirect URL based on the current user
        // We need to make sure the user is fetched to build the correct URL server-side
        middleware: ['fetch-user'],
        owners: ['bot-squad-lifecycle-front'],
      },
    },
    {
      path: 'student-discount',
      name: DISCOUNT.STUDENT,
      component: () => import('~/scopes/cms/Landing.vue'),
      props: {
        simpleLayout: true,
      },
      meta: {
        // Similar to MilitaryDiscount: required because the StudentDiscount page builds a redirect URL based on the current user
        // We need to make sure the user is fetched to build the correct URL server-side

        middleware: ['fetch-user'],
        owners: ['bot-squad-lifecycle-front'],
      },
    },
  ]
}
