import type { RouteRecordRaw } from 'vue-router'

import { CUSTOMER_CONVERSATION } from './route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: 'help-request/:resolutionId',
      name: CUSTOMER_CONVERSATION.RESOLUTION,
      component: () => import('./pages/Conversation/Conversation.vue'),
      meta: {
        auth: {
          required: true,
        },
        owners: ['bot-squad-care-platform-front'],
        layout: false,
      },
    },
    {
      path: 'help-request/care-folder/:careFolderId',
      name: CUSTOMER_CONVERSATION.CARE_FOLDER,
      component: () => import('./pages/Conversation/Conversation.vue'),
      meta: {
        auth: {
          required: true,
        },
        owners: ['bot-squad-care-platform-front'],
        layout: false,
      },
    },
  ]
}
