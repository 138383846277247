import type { RouteRecordRaw } from 'vue-router'

import { PRICE_GUIDE } from './routes-constants'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/:locale/price-guide/:productModelSlug',
      name: PRICE_GUIDE.PRODUCT_MODEL,
      component: () => import('./pages/PriceGuideProductModel.vue'),
      meta: {
        owners: ['bot-squad-content-front'],
      },
    },
  ]
}
