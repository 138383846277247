import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useRequestURL,
  useRuntimeConfig,
} from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { splitHostname } from '@backmarket/utils/url/splitHostname'

type PaymentRouteMeta = {
  /**
   * When true, users will be redirected to the payment subdomain when entering
   * the route.
   */
  subdomainEnabled: boolean
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * Configure payment-specific behavior for the route, such as whether to
     * redirect to the payment subdomain.
     */
    payment?: PaymentRouteMeta
  }
}

const DEFAULT_ROUTE_META: PaymentRouteMeta = {
  subdomainEnabled: false,
}

const NORMAL_DOMAIN = 'backmarket'
const WWW_SUBDOMAIN = 'www'
const PAYMENT_PART = 'payment'

export function getHostInfo(url: URL) {
  const { domain, subdomain, extension } = splitHostname(url.href)

  // Fallback for unexpected local cases, such as https://localhost, etc.
  if (domain !== NORMAL_DOMAIN) {
    return {
      regularHost: url.host,
      paymentHost: url.host,
      isPaymentHost: false,
    }
  }

  const isProduction = [WWW_SUBDOMAIN, PAYMENT_PART].includes(subdomain)
  if (!isProduction) {
    const prefix = subdomain.replace(new RegExp(`.${PAYMENT_PART}$`), '')

    return {
      regularHost: `${prefix}.${domain}.${extension}`,
      paymentHost: `${prefix}.${PAYMENT_PART}.${domain}.${extension}`,
      isPaymentHost: subdomain.endsWith(`.${PAYMENT_PART}`),
    }
  }

  return {
    regularHost: `${WWW_SUBDOMAIN}.${domain}.${extension}`,
    paymentHost: `${PAYMENT_PART}.${domain}.${extension}`,
    isPaymentHost: subdomain === PAYMENT_PART,
  }
}

export default defineNuxtRouteMiddleware(
  // eslint-disable-next-line consistent-return
  function paymentSubdomainMiddleware(routeTo) {
    const { countryCode } = useMarketplace().market
    const config = useRuntimeConfig().public.payment
    if (!config.FF_SUBDOMAIN_ENABLED_COUNTRIES.includes(countryCode)) {
      return undefined
    }

    const routeMeta = {
      ...DEFAULT_ROUTE_META,
      ...(routeTo.meta?.payment || {}),
    }

    const url = new URL(useRequestURL().origin)
    url.pathname = routeTo.fullPath
    const { regularHost, paymentHost, isPaymentHost } = getHostInfo(url)

    // Redirect from regular to payment subdomain
    if (routeMeta.subdomainEnabled && !isPaymentHost) {
      url.host = paymentHost

      return navigateTo(url.href, { external: true })
    }

    // Redirect from payment to regular subdomain
    if (!routeMeta.subdomainEnabled && isPaymentHost) {
      url.host = regularHost

      return navigateTo(url.href, { external: true })
    }
  },
)
