export type ExperimentName = `experiment.${string}`
type Experiment = {
  defaultValue: 'noVariation'
  urlPatterns: Array<string>
}

/**
 * This dictionary holds the configuration for all the experiments currently
 * being assigned by the experiments worker.
 *
 * @property defaultValue - Must be set to noVariation for all of them.
 * @property urlPatterns - Allows you to scope each experiment to their
 * associated set of pages. Those patterns are used on the worker side
 * to know if the experiment will be used to process the current request
 * or not. It helps us improving our cache hit ratio, and thus the
 * overall server-side performance.
 */
export const config = {
  'experiment.globalAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page */],
  },
  'experiment.landingAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* Landing page */],
  },
  'experiment.productAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.checkoutAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.homeCMSPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page - permanent AB test */],
  },
  'experiment.goodDealsEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - GoodDeal */,
    ],
  },
  'experiment.recyclingEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - Recycling */,
    ],
  },
  'experiment.holidaysEventPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/e/.*$' /* Event pages - permanent AB test - Holidays */,
    ],
  },
  'experiment.homeBuybackPageName': {
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/buyback/.*' /* All Buyback Pages - permanent AB test */,
    ],
  },
  'experiment.serviceFeePriceGroup24': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },

  /* Circularity Customer */
  'experiment.ppBuybackDropdown': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.discountedPriceInProductCard': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutDiscountedPrice
     * withDiscountedPrice
     * withDiscountedPriceOnlyAfter */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.autoAcceptedOffer': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutAutoAcceptedOffer
     * withAutoAcceptedOffer */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.oneQuestionByStep': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutOneQuestionByStep
     * withOneQuestionByStep */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.swapEmbeddedStep': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutSwapEmbeddedStep
     * withSwapEmbeddedStep */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },

  'experiment.ppDeliveryBeforeXmas': {
    defaultValue: 'noVariation',
    /** Variations:
     * withDeliveryBeforeXmas
     * withoutDeliveryBeforeXmas
     *  */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.masterPP': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutMasterPP
     * withMasterPP
     */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
    ],
  },
  'experiment.ppCheapestPricesOnPickers': {
    defaultValue: 'noVariation',
    /** Variations:
     * closest_configuration
     * cheapest_configuration
     * cheapest_configuration_by_grade
     */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppMixAndMatchEverywhere': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.insuranceMonthlySubscription': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  // https://backmarket.atlassian.net/wiki/spaces/2C/pages/4526833841/Filtering+cart+insurances+monthly+only+vs+upfront+only
  'experiment.monthlyFilterExperiment': {
    defaultValue: 'noVariation',
    /** Variations:
     *  noFilter
     * 	monthlyOnly
     * 	upfrontOnly
     */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.cartContentCTA': {
    defaultValue: 'noVariation',
    /** Variations:
     * cartContentNextStep
     * cartContentCheckout */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.killOneyFr': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.enableClearpayUK': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.enableKlarnaIT': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.ppImmersiveStories': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppInStock': {
    defaultValue: 'noVariation',
    /** Variations:
     * dontDisplayInStock
     * displayInStock */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppHideOutOfStockPickers': {
    defaultValue: 'noVariation',
    /** Variations:
     * hideOutOfStockPickers
     * showOutOfStockPickers */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppNoGrade': {
    defaultValue: 'noVariation',
    /** Variations:
     * withNoGrade
     * withoutNoGrade */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppVerifiedRefurbishedCarousel': {
    defaultValue: 'noVariation',
    /** Variations:
     * withCarousel
     * withoutCarousel */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.photosInReview': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutPhotos
     * withPhotosDefaultRelevance
     * withPhotosNewRelevance */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
      '^/[a-z]{2}-[a-z]{2}/s/.*' /* Seller page */,
      '^/[a-z]{2}-[a-z]{2}/r/.*' /* Reviews pages */,
    ],
  },
  'experiment.verifiedPurchaseInReview': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutVerifiedPurchase
     * withVerifiedPurchase */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
      '^/[a-z]{2}-[a-z]{2}/r/.*' /* Reviews pages */,
    ],
  },
  'experiment.paymentFormVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.reduceHeader': {
    defaultValue: 'noVariation',
    /** Variations:
     * big
     * small */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.removeListingFacets': {
    /** Variations:
     * with
     * without */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */],
  },
  'experiment.checkoutContentCTA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.cartStockTagPrice': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.salesPopularityTag': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.cartDeliveryDisplay': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.addressV2EndpointMigration': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.cartLockedBlock': {
    /** Variations:
     * original : cartLockedBlockDisabled
     * new : cartLockedBlockEnabled */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.countrySwitchRedirection': {
    /** Variations:
     * withRedirection
     * withoutRedirection */
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.ppStickyScrolling': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutStickyScroll
     * hybridStickyScroll
     * withStickyScroll */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppPremiumGradeUsMacBook': {
    defaultValue: 'noVariation',
    /** Variations:
     * withPremiumGrade
     * withoutPremiumGrade */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppESimExperiment': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutESimExperiment
     * withESimExperiment */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.socialLoginFirst': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutSocialLoginFirst
     * withSocialLoginFirst */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.inLineHeader': {
    defaultValue: 'noVariation',
    /** Variations:
     * with
     * without */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
} satisfies Record<ExperimentName, Experiment>
