import { defineNuxtRouteMiddleware, navigateTo, useRequestURL } from '#imports'

import { getHostInfo } from '../../../middleware/paymentSubdomain.global'
import { usePaymentNoSsrModuleOptions } from '../composables/usePaymentNoSsrModuleOptions'

export default defineNuxtRouteMiddleware(
  // eslint-disable-next-line consistent-return
  function paymentNoSsrMiddleware(routeTo) {
    const { enabled, landingPageRoutePath } = usePaymentNoSsrModuleOptions()

    if (
      process.server &&
      enabled &&
      getHostInfo(useRequestURL()).isPaymentHost &&
      !routeTo.fullPath.startsWith(landingPageRoutePath)
    ) {
      return navigateTo({
        path: `${landingPageRoutePath}${routeTo.fullPath}`,
        query: routeTo.query,
      })
    }
  },
)
