import type { RouteRecordRaw } from 'vue-router'

import { CUSTOMER_DELIVERY } from '~/scopes/customer-delivery/route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/:locale/live-identity-check/:publicId',
      name: CUSTOMER_DELIVERY.LIVE_IDENTITY_CHECK_CONSENT,
      component: () =>
        import('./pages/LiveIdentityCheckConsent/LiveIdentityCheckConsent.vue'),
      meta: {
        owners: ['bot-squad-delivery-experience-front'],
        layout: false,
      },
    },
  ]
}
